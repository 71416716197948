<template>
  <v-container id="degustation">
    <LineCniel class="tw-my-8" />
    <div class="tw-flex tw-flex-col tw-items-center">
      <PrimaryTitle title="Côté dégustation"/>
      <PrimaryText paragraph="Découvrez les outils de communication "/>
      <PrimaryText paragraph="pour sensibiliser vos élèves et animer votre restaurant"/>
      <img src="@/assets/images/platform-degital2.jpg" class="degustation-image" alt="commandez gratuitement" />
      <PrimaryButtonComponent @click="go_to_kit_communication" class="command-button">
        Commander
      </PrimaryButtonComponent>
    </div>
    <LineCniel class="tw-my-8" />
  </v-container>
</template>

<script>
import PrimaryTitle from "../../../components/common/PrimaryTitle";
import PrimaryText from "../../../components/common/PrimaryText";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import LineCniel from "../../../components/common/LineCniel";
export default {
  name: "Degustation",
  components: {LineCniel, PrimaryButtonComponent, PrimaryText, PrimaryTitle},
  methods: {
    go_to_kit_communication() {
      this.$router.push({ name: 'Public.Order' });
    }
  }
}
</script>

<style scoped>

</style>