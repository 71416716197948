<template>
  <div id="home">
    <HeroBanner />
    <PlatformDigital />
    <Restauration />
    <GuideTransfert />
    <Degustation />
    <BackToTop />

  </div>
</template>

<script>
import HeroBanner from "../components/HeroBanner";
import PlatformDigital from "../components/PlatformDigital";
import Restauration from "../components/Restauration";
import GuideTransfert from "../components/GuideTransfert";
import Degustation from "../components/Degustation";
import BackToTop from "../../../components/common/BackToTop";
export default {
  name: "Home",
  components: {BackToTop, Degustation, GuideTransfert, Restauration, PlatformDigital, HeroBanner},
  data() {
    return {}
  },

  mounted() {
  }
}
</script>

<style scoped>

</style>