import ApiService from "@/services/api.service";

export const checkCompanyToken = token => {
    return ApiService.get(`check-company-token/${token}`);
};

export const checkNumberOfOrders = () => {
    return ApiService.get(`check-number-orders`);
};

export const postOrder = data => {
    return ApiService.post(`post-company-order`, data);
};

export const transferOrder = data => {
    return ApiService.post(`transfer-order`, data);
};