<template>
  <div id="command">
    <KitBanner />
    <SupportsDetail />
    <CommandForm />
    <TransfertInfo />
    <MilkWeek />
    <DirectionButtons />

    <v-dialog
        overlay-color="#151F6D"
        v-model="kitModal"
        persistent
        transition="dialog-top-transition"
        width="70%"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <p class="form-modal-title">
          Le programme est victime de son succès !<br>
          Le solde de 1000 kits a été atteint.<br>
          Nous ne prenons plus de commandes.
        </p>
        <p class="form-modal-text">
          À bientôt, pour une nouvelle campagne avec nos partenaires.
        </p>
        <PrimaryButtonComponent @click="$router.push({ name: 'Public.Home' })" class="form-modal-button">
          Retour
        </PrimaryButtonComponent>
      </div>

    </v-dialog>

  </div>
</template>

<script>
import KitBanner from "../components/KitBanner"
import SupportsDetail from "../components/SupportsDetail"
import CommandForm from "../components/CommandForm"
import TransfertInfo from "../components/TransfertInfo"
import DirectionButtons from "../components/DirectionButtons";
import {checkNumberOfOrders} from "../services/appApi";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import MilkWeek from "../components/MilkWeek";
export default {
  name: "Command",
  components: {MilkWeek, PrimaryButtonComponent, DirectionButtons, TransfertInfo, CommandForm, SupportsDetail, KitBanner},
  data() {
    return {
      kitModal: false,
    }
  },

  mounted() {
    this.check_number_orders();
  },

  methods: {
    check_number_orders() {
      checkNumberOfOrders()
        .then((resp) => {
          if (resp.data.data) {
            this.kitModal = true;
          }
        })
        .catch(({response}) => {
          console.log(response);
        });
    }
  }
}
</script>