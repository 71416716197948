const snackbarPlugin = {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error("Please provide vuex store.");
    }

    Vue.prototype.$snackbar = {
      showAlertMessage: function ({ message = "", type = "" }) {
        store.commit("showAlertMessage", { message, type }, { root: true });
      },
    };
  },
};
export default snackbarPlugin;
