import { render, staticRenderFns } from "./PrimaryButtonComponent.vue?vue&type=template&id=07d80274&scoped=true&"
import script from "./PrimaryButtonComponent.vue?vue&type=script&lang=js&"
export * from "./PrimaryButtonComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07d80274",
  null
  
)

export default component.exports