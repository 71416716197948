<template>
  <footer id="public-footer">

    <v-dialog
        overlay-color="#151F6D"
        v-model="cguModal"
        transition="dialog-top-transition"
        width="90%"
    >
      <div>
        <span class="tw-mb-6 tw-font-bold tw-text-2xl tw-text-black-100 tw-cursor-pointer" @click="cguModal = false">X</span>
        <CGU />
      </div>
    </v-dialog>

    <v-dialog
        overlay-color="#151F6D"
        v-model="politiqueModal"
        transition="dialog-top-transition"
        width="90%"
    >
      <div>
        <span class="tw-mb-6 tw-font-bold tw-text-2xl tw-text-black-100 tw-cursor-pointer" @click="politiqueModal = false">X</span>
        <Politique />
      </div>
    </v-dialog>

    <div class="tw-flex tw-flex-col">
      <img src="@/assets/images/footer-europe.svg" alt="footer image" />
      <LineCniel />
      <p class="footer-partner-text">
        Ce programme a été développé en collaboration avec :
      </p>
      <div class="tw-flex tw-flex-row tw-justify-center tw-space-x-2 md:tw-space-x-6">
        <img src="@/assets/images/partners/aji.svg" class="partner-aji" alt="partner logo" />
        <img src="@/assets/images/partners/agores.svg" class="partner-agores" alt="partner logo" />
        <img src="@/assets/images/partners/restau-co.svg" class="partner-restau-co" alt="partner logo" />
      </div>
      <div class="footer-mention-box">
        <p class="footer-mention-text tw-hidden md:tw-block">
          Le contenu de cette campagne de promotion reflète uniquement la position de l'auteur
          et relève de sa seule responsabilité. La Commission européenne et l’Agence exécutive
          européenne pour la recherche (REA) déclinent toute responsabilité quant à toute
          utilisation qui pourrait être faite des informations qui y figurent.
        </p>
        <div class="tw-flex tw-flex-row tw-items-baseline tw-justify-center md:tw-justify-start">
          <span class="footer-mention-text tw-underline tw-cursor-pointer" @click="cguModal = true">Mentions légales & CGU</span>
          <span class="footer-mention-text tw-mx-2">|</span>
          <span class="footer-mention-text tw-underline tw-cursor-pointer" @click="politiqueModal = true">Données personnelles</span>
          <span class="footer-mention-text tw-mx-2">|</span>
          <span class="footer-mention-text tw-underline tw-cursor-pointer">
            <a class="tw-text-white" href="javascript:Didomi.preferences.show()">Gestion des cookies</a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LineCniel from "../common/LineCniel";
import CGU from "./CGU";
import Politique from "./Politique";
export default {
  name: "Footer",
  components: {Politique, CGU, LineCniel},
  data() {
    return {
      cguModal: false,
      politiqueModal: false,

    }
  },
}
</script>