<template>
  <div id="milk-week">
    <img src="@/assets/images/banner-game.jpg" class="image-milk" alt="" />
  </div>
</template>

<script>
export default {
  name: "MilkWeek"
}
</script>

<style scoped>

</style>