import Public from "../Public";
import Home from "../features/home/pages/Home";
import Command from "../features/command/pages/Command";
import PrivacyPolicy from "../PrivacyPolicy";

const publicRoutes = [
    {
        name: "Public",
        path: "",
        component: Public,
        redirect: {name: 'Public.Home'},
        meta: {},
        children: [
            {
                name: "Public.Home",
                path: "/",
                component: Home,
                meta: {},
            },
            {
                name: "Public.Order",
                path: "/commande",
                component: Command,
                meta: {},
            },
        ]
    },
    {
        name: "Public.Politique",
        path: "/privacyPolicy",
        component: PrivacyPolicy,
        meta: {},
    },
]

export default publicRoutes;