<template>
  <div id="transfert-info-container">
    <v-container class="guide-transfert">
      <div class="tw-flex tw-flex-col form-top">
        <PrimaryTitle title="Vous souhaitez transférer l’information"/>
        <div id="transfert-form">
          <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-space-x-3 form-top">
      <span class="form-top-text">
        Pour envoyer l’information par e-mail, remplissez le formulaire de contact :
      </span>
            <img class="tw-cursor-pointer form-button-down-up" v-if="!displayForm" @click="displayFormHandler" src="@/assets/images/form-button-down.svg" alt="">
            <img class="tw-cursor-pointer form-button-down-up" v-if="displayForm" @click="displayFormHandler" src="@/assets/images/form-button-up.svg" alt="">
          </div>
          <TransfertForm @closeForm="displayFormHandler" v-if="displayForm"/>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import PrimaryTitle from "../../../components/common/PrimaryTitle";
import TransfertForm from "./TransfertForm";
export default {
  name: "TransfertInfo",

  components: {TransfertForm, PrimaryTitle},

  data() {
    return {
      displayForm: false,
    }
  },

  methods: {
    displayFormHandler() {
      this.displayForm = !this.displayForm;
    },
  }
}
</script>

<style scoped>

</style>