<template>
  <div id="support-slider-item-mobile">
    <img :src="require(`@/assets/images/support/${supportImg}`)" alt="support" />
    <p class="support-slider-item-mobile-text">
      <strong>{{supportTitle}}</strong><br>
      {{supportText}}
    </p>
  </div>
</template>

<script>
export default {
  name: "SupportItemCardMobile",
  props: {
    supportImg: {
      type: String,
      default: '',
    },
    supportTitle: {
      type: String,
      default: '',
    },
    supportText: {
      type: String,
      default: '',
    }
  }
}
</script>

<style scoped>

</style>