<template>
  <div class="tw-flex tw-flex-col" id="kit-banner">
    <span class="breadcrumb">
      <span
          class="tw-cursor-pointer"
          @click="$router.push({ name: 'Public.Home' })">
        Accueil
      </span>
       /
      <strong>Page de commande</strong></span>
    <div class="tw-hidden md:tw-flex tw-mt-4 md:tw-ml-24 kit-banner-container">
      <img src="@/assets/images/kit-banner.jpg" class="kit-img" alt="kit de communiication" />
      <div class="tw-flex tw-flex-col tw-justify-evenly tw-mr-24">
         <span class="kit-banner-title">
           Kit de communication
         </span>
        <p class="kit-banner-paragraph">
          Pour vous aider à sensibiliser les enfants à une alimentation équilibrée au
          quotidien incluant des produits de qualité, nous vous proposons un kit de communication
          entièrement gratuit pour animer vos restaurants.
          Le kit de communication vous sera livré pour un déploiement avant ou après la
          Toussaint selon votre choix lors de la commande.
        </p>
        <p class="kit-banner-paragraph2">
          Si vous souhaitez partager l’information à la personne habilitée à
          réaliser ce type de commande >
          <a href="#" class="tw-underline tw-cursor-pointer tw-font-bold"
             style="color: #151F6D"
             v-scroll-to="'#transfert-info-container'"
          >
            cliquez ici.
          </a>
        </p>
        <a href="#" v-scroll-to="'#command-form'">
          <PrimaryButtonComponent class="kit-banner-button">
            Accèder au formulaire de commande
          </PrimaryButtonComponent>
        </a>
      </div>
    </div>
    <div class="tw-flex tw-flex-col md:tw-hidden">
      <span class="kit-banner-title">
       Kit de <br> communication
      </span>
      <img src="@/assets/images/kit-banner.jpg" class="kit-img" alt="kit de communiication" />
      <p class="kit-banner-paragraph">
        Pour vous aider à sensibiliser les enfants à une alimentation équilibrée au
        quotidien incluant des produits de qualité, nous vous proposons un kit de communication
        entièrement gratuit pour animer vos restaurants.<br>
        Le kit de communication vous sera livré pour un déploiement avant ou après la
        Toussaint selon votre choix lors de la commande.
      </p>
      <p class="kit-banner-paragraph2">
        Si vous souhaitez partager l’information à la personne habilitée à
        réaliser ce type de commande >
        <a href="#" class="tw-underline tw-cursor-pointer tw-font-bold"
           style="color: #151F6D"
           v-scroll-to="'#transfert-info-container'"
        >
          cliquez ici.
        </a>
      </p>
      <a href="#" v-scroll-to="'#command-form'">
        <PrimaryButtonComponent class="kit-banner-button">
          Accèder au formulaire de commande
        </PrimaryButtonComponent>
      </a>
    </div>
  </div>
</template>

<script>
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
export default {
  name: "KitBanner",
  components: {PrimaryButtonComponent}
}
</script>

<style scoped>

</style>