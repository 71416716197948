<template>
  <div class="">
    <perfect-scrollbar
        ref="scroll"
        style="height: 380px"
    >
    <p class="form-modal-title">
      Mentions légales & CGU
    </p>
    <p class="tw-mt-4 tw-text-center tw-font-bold tw-text-2xl">
      MENTIONS LEGALES
    </p>
    <p class="tw-mt-2 tw-font-bold">
      Editeur
    </p>
    <p class="tw-text-justify">
      Conformément à l’article 43-10 de la loi du 1er septembre 1986 relative à la liberté de
      communication (modifiée par la loi L200-719 du 1er août 2000), vous êtes informé que vous
      vous trouvez actuellement sur le site web produitslaitiersrestaurationscolaire.fr
      (ci-après le « Site »), édité par le Centre National Interprofessionnel de l’Économie
      Laitière (CNIEL), association à but non lucratif immatriculée au répertoire SIREN sous le
      numéro 300 817 954.<br>
      Le site produitslaitiersrestaurationscolaire.fr a été réalisé à l’initiative du
      CNIEL afin d’animer le programme LES PRODUITS LAITIERS ET LA RESTAURATION SCOLAIRE
      promu dans le cadre de la campagne « l’Europe a du bon », financée avec l’aide de l’Union Européenne.
    </p>
    <p class="tw-text-justify">
      Directeur de la Publication : Madame Caroline LE POULTIER<br>
      Adresse postale du CNIEL :<br>
      42 rue de Châteaudun<br>
      75314 Paris Cedex 09<br>
      Tél : 01.49.70.71.71<br>
      Fax : 01.42.80.63.52 / 01.42.80.63.57<br>
    </p>
    <p class="tw-mt-2 tw-font-bold">
      Réalisation du site
    </p>
    <p class="tw-text-justify">
      Conception, création graphique et développement :<br>
      La société New Venise, SAS<br>
      37 à 41 rue Fernand Pelloutier<br>
      92773 Boulogne-Billancourt Cedex<br>
      hbokobza@venise.com<br>
      Tel : +33 (0)1 70 08 70 08<br>
    </p>
    <p class="tw-text-justify">
      Crédits photographies et illustrations<br>
      Tous les éléments du site (notamment graphismes, photos, logos, textes, sons, animations...)
      sont protégés par la législation sur le droit d'auteur et la propriété intellectuelle.
      La reproduction des documents du site est autorisée à des fins exclusives d'information
      pour un usage personnel et privé : toute reproduction et toute utilisation de copie réalisée
      à d'autres fins est expressément interdite. Tous droits de reproduction de photographies réservés
      à Shutterstock, Cavan images, Julien Mota et Bruno Souverbie.<br>
      L'ensemble de ce site relève de la législation française et internationale sur
      le droit d'auteur et la propriété intellectuelle.<br>
      Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables,
      les représentations iconographiques et photographiques.<br>
      Hébergeur<br>
      OVH SAS<br>
      situé au 2 rue Kellermann – 59100 Roubaix – France<br>
    </p>
    <p class="tw-mt-12 tw-text-center tw-font-bold tw-text-2xl tw-bg">
      CONDITIONS GENERALES D’UTILISATION
    </p>
    <p class="tw-text-justify">
      Le Site et ses contenus sont réservés à l’usage des collaborateurs des Collèges
      et/ou Lycées désireux d’animer le programme LES PRODUITS LAITIERS ET LA RESTAURATION
      SCOLAIRE (ci-après le « Programme »), promu par le CNIEL dans le cadre de la campagne
      « l’Europe a du bon », financée avec l’aide de l’Union Européenne, pour animer leur établissement,
      notamment dans les environnements constitués des cantines scolaires ou des réfectoires.
    </p>
    <p class="tw-text-justify">
      L’utilisateur reconnaît avoir vérifié que la configuration informatique utilisée ne
      contient aucun virus et qu’elle est en bon état de fonctionnement.<br>
      Les informations nominatives, concernant l’utilisateur et les collaborateurs des Collèges
      et/ou Lycées sont à usage exclusif de l’animation du programme LES PRODUITS LAITIERS
      ET LA RESTAURATION SCOLAIRE.
    </p>
    <p class="tw-text-justify">
      La structure générale, ainsi que les logiciels, textes, images animées ou fixes, sons,
      savoir-faire, dessins, graphismes (…) et tout autre élément composant le site constituent,
      ensemble, une œuvre protégée par les lois françaises et internationales en vigueur sur le
      droit d’auteur et la propriété intellectuelle dont le CNIEL est le propriétaire exclusif.
      Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans
      l’autorisation expresse du CNIEL, est interdite et constituerait une contrefaçon sanctionnée
      par les articles L.122-4, L 335-2 et suivants du Code de la propriété intellectuelle.
    </p>
    <p class="tw-text-justify">
      Pour bénéficier des outils mis à leur dispositions (Guide acheteur et Recettes
      du Chef en téléchargement, Kit de communication en livraison), les Collèges et/ou
      Lycées doivent s’inscrire au Programme en renseignant les formulaires prévus à cet effet.
      Toutes les informations demandées sont nécessaires et obligatoires afin que l’accès au
      Programme soit conforme et actif. Toutes informations erronées par manquement ou volontairement,
      relèvent de la responsabilité des utilisateurs Collèges et/ou Lycées.
    </p>
    <p class="tw-text-justify">
      Il en est de même des données de collaborateurs versées par les utilisateurs.
    </p>
    <p class="tw-text-justify">
      Les marques, ainsi que les logos figurant sur le site, celles et ceux des organisations et
      des partenaires, sont des marques (semi-figuratives ou non) déposées. Toute reproduction
      totale ou partielle de ces marques ainsi que toute représentation totale ou partielle de ces
      marques et/ou logos, effectuées à partir des éléments du site sans l’autorisation expresse du
      CNIEL ou de ces dernières sont donc prohibées, au sens de l’article L 713-2 du Code de la
      propriété intellectuelle.
    </p>
    <p class="tw-text-justify">
      Les liens hypertextes mis en place dans le cadre du présent site en direction d’autres
      ressources présentes sur le réseau Internet et notamment vers ses partenaires ont fait
      l’objet d’une autorisation préalable.
    </p>
    <p class="tw-text-justify">
      Pour la réalisation du site, New Venise a mis en place un cryptage pour sécuriser
      la transmission de données sur internet (Cryptage SSL 128 bits).<br>
      Toutefois, l’utilisateur déclare être informé du défaut de fiabilité du réseau internet,
      tout spécialement en termes de sécurité relative à la transmission des données,
      à la continuité dans l’accès au Site, aux performances en termes de volume et de
      rapidité de transmission des données.<br>
      <span class="tw-underline">Base de données - hébergement</span>
    </p>
    <p class="tw-text-justify">
      L’utilisateur est informé que la Base de données, composée des informations recueillies sur
      le Site, est  hébergée en France chez OVH par New Venise
    </p>
    <p class="tw-text-justify tw-underline">
      Évolution et mise à disposition du Site
    </p>
    <p class="tw-text-justify">
      Le CNIEL se réserve le droit de faire évoluer le Site.
    </p>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "CGU"
}
</script>

<style scoped>

</style>