<template>

  <div class="tw-flex tw-justify-end tw-mx-2 md:tw-mx-12 tw-my-6">
    <a href="#" v-scroll-to="'#public-header'">
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.0014 4.15234C13.5118 4.15443 4.16602 13.5003 4.16602 24.9878C4.1681 36.4753 13.5139 45.8211 24.9993 45.8211C36.4889 45.8211 45.8348 36.4753 45.8348 24.9878C45.8348 13.5003 36.4889 4.15443 25.0014 4.15234ZM24.9993 41.6544C15.8118 41.6544 8.33477 34.1773 8.33268 24.9878C8.33268 15.7982 15.8098 8.32109 25.0014 8.31901C34.191 8.32109 41.6681 15.7982 41.6681 24.9878C41.6681 34.1773 34.191 41.6544 24.9993 41.6544Z" fill="#151F6D"/>
        <path d="M25.002 16.6689L16.6582 25.0127H22.9186V33.3335H27.0853V25.0127H33.3436L25.002 16.6689Z" fill="#151F6D"/>
      </svg>
    </a>

  </div>
</template>

<script>
export default {
  name: "BackToTop",
}
</script>

<style scoped>

</style>