<template>
  <v-btn
    elevation="1"
    text
    class="primary-btn"
    v-bind="$attrs"
    v-on="$listeners"
    rounded
    large
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "PrimaryButtonComponent",
  props: ["onClick"],
};
</script>

<style scoped></style>
