<template>
  <div>
    <v-container>
      <LineCniel class="tw-mt-8" />
    </v-container>
    <v-container id="restauration">
      <div class="tw-flex tw-flex-col md:tw-items-center restauration-text-container">
        <PrimaryTitle title="Côté restauration"/>
        <PrimaryText paragraph="Retrouver les outils des produits laitiers"/>
        <div class="tw-hidden md:tw-flex md:tw-flex-col">
          <RestaurationCard
              title="Guide acheteur"
              img="guide-acheteur.jpg"
              text="Comment mieux gérer vos achats, vos stocks et vos livraisons, sans oublier de
              diversifier vos menus et de maitriser vos coûts ?"
              text2="Toutes les informations vos achats de produits laitiers en restauration
              scolaire à retrouver dans un seul et même guide."
              button-title="Télécharger"
              fileToDownload="./download/Guide acheteur.pdf"
              :order="[1,2]"
          />
          <RestaurationCard
              title="Les recettes du chef"
              img="chef_recettes.jpg"
              text="Fort d’une longue expérience dans la restauration scolaire,
              le chef Yannick Strottner vous propose 10 recettes à base de produits laitiers.
              Des recettes créatives pour diversifier les goûts et les apports en protéines,
              ainsi que des solutions pour des repas végétariens."
              button-title="Télécharger"
              fileToDownload="./download/Livret recettes.pdf"
              :order="[2,1]"
          />
        </div>
        <div class="tw-flex tw-flex-col md:tw-hidden">
          <RestaurationCard
              title="Guide acheteur"
              img="guide-acheteur-sm.jpg"
              text="Comment mieux gérer vos achats, vos stocks et vos livraisons, sans oublier de
              diversifier vos menus et de maitriser vos coûts ?"
              text2="Toutes les informations vos achats de produits laitiers en restauration
              scolaire à retrouver dans un seul et même guide."
              button-title="Télécharger"
              fileToDownload="./download/Guide acheteur.pdf"
              :order="[1,2]"
          />
          <RestaurationCard
              title="Les recettes du chef"
              img="chef_recettes-sm.jpg"
              text="Fort d’une longue expérience dans la restauration scolaire, le chef Yannick Strottner
            vous propose 10 recettes à base de produits laitiers. Des recettes créatives pour
            diversifier les goûts et les apports en protéines, ainsi que des solutions pour des
            repas végétariens."
              text2="Vous souhaitez recevoir votre livret numérique par e-mail inscrivez-vous."
              button-title="Télécharger"
              fileToDownload="./download/Livret recettes.pdf"
              :order="[2,1]"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import PrimaryTitle from "../../../components/common/PrimaryTitle";
import PrimaryText from "../../../components/common/PrimaryText";
import RestaurationCard from "./RestaurationCard";
import LineCniel from "../../../components/common/LineCniel";
export default {
  name: "Restauration",
  components: {LineCniel, RestaurationCard, PrimaryText, PrimaryTitle}
}
</script>

<style scoped>

</style>