<template>
  <div id="command-form">
    <v-dialog
        overlay-color="#151F6D"
        v-model="sentModal"
        persistent
        transition="dialog-top-transition"
        width="70%"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <p class="form-modal-title">
          Merci pour votre participation au programme<br>
          “Les produits laitiers & la restauration scolaire”.
        </p>
        <p class="form-modal-text">
          Votre commande est bien prise en compte, vous allez recevoir un mail de confirmation.
        </p>
        <PrimaryButtonComponent @click="button_retour_modal" class="form-modal-button">
          Retour
        </PrimaryButtonComponent>
      </div>
    </v-dialog>

    <v-dialog
        overlay-color="#151F6D"
        v-model="existOrderModal"
        persistent
        transition="dialog-top-transition"
        width="70%"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <p class="form-modal-title">
          Nous avons déjà enregistré une commande à cette adresse et pour cet établissement.
        </p>
        <p class="form-modal-text">
          Une seule commande est possible par établissement.
        </p>
        <PrimaryButtonComponent @click="$router.push({ name: 'Public.Home'})" class="form-modal-button">
          Retour
        </PrimaryButtonComponent>
      </div>

    </v-dialog>

    <v-container class="command-form-container">
      <PrimaryTitle title="Formulaire de commande"/>
      <div class="tw-flex tw-flex-col md:tw-flex-row">
        <div class="tw-flex tw-flex-col md:tw-w-1/2 mdtw-mx-8">
          <h5 class="form-guide-title">Vos informations :</h5>
          <SelectFieldComponent
              :error="!!!!validation_form.gender"
              class="tw-w-1/2 md:tw-w-1/3"
              :items="genderItems"
              v-model="command_form.gender"
              title="Civilité*"
              label="Mr"
          />
          <TextFieldComponent
              :error="!!validation_form.firstName"
              class="tw-w-full md:tw-w-1/2"
              v-model="command_form.firstName"
              title="Prénom*"
              label="John"
          />
          <TextFieldComponent
              :error="!!validation_form.lastName"
              class="tw-w-full md:tw-w-1/2"
              v-model="command_form.lastName"
              title="Nom*"
              label="Doe"
          />
          <TextFieldComponent
              :error="!!validation_form.email"
              class="tw-w-full"
              v-model="command_form.email"
              title="E-mail*"
              label="jdoe@email.com"
          />
        </div>
        <div class="tw-flex tw-flex-col md:tw-w-1/2 md:tw-mx-8">
          <h5 class="form-guide-title">Renseignez l’adresse de l’établissement :</h5>
          <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-6">
            <SelectFieldComponent
                :error="!!validation_form.type"
                class="tw-w-1/2 md:tw-w-1/3"
                :items="typeItems"
                v-model="command_form.type"
                title="Type*"
                label="Collège"
            />
            <TextFieldComponent
                :error="!!validation_form.department"
                class="tw-w-full md:tw-w-2/3"
                v-model="command_form.department"
                title="Nom de l'établissement scolaire*"
                label="Doe"
            />
          </div>
          <TextFieldComponent
              :error="!!validation_form.department_address"
              class="tw-w-full"
              v-model="command_form.department_address"
              title="Adresse de l'établissement scolaire*"
              label="Adresse"
          />
          <TextFieldComponent
              :error="!!validation_form.compl_info"
              class="tw-w-full"
              v-model="command_form.compl_info"
              title="Informations complémentaires"
              label="Adresse"
          />
          <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-14">
            <TextFieldComponent
                :error="!!validation_form.postal_code"
                class="tw-w-full md:tw-w-2/6"
                v-model="command_form.postal_code"
                title="Code postal*"
                label="XXXXX"
            />
            <TextFieldComponent
                :error="!!validation_form.city"
                class="tw-w-full md:tw-w-3/6"
                v-model="command_form.city"
                title="Ville*"
                label="Ville"
            />
          </div>
        </div>
      </div>
    </v-container>
    <LineCniel class="tw-mx-12"/>
    <v-container class="command-form-container">
      <div class="tw-flex tw-flex-col md:tw-flex-row">
        <div class="tw-flex tw-flex-col md:tw-w-1/2 mdtw-mx-8">
          <h5 class="form-guide-title">Nombre de couverts servis : </h5>
          <p class="guide-contact-text">
            Renseignez ici le nombre de couverts servis, en moyenne par jour, dans votre restaurant.
          </p>
          <h5 class="tw-mt-6 form-guide-title"
              :style="validation_form.multi_restaurant ? 'color: #FB4949' : ''"
          >Votre établissement possède plusieurs restaurants ?  </h5>
        </div>
        <div class="tw-flex tw-flex-col md:tw-w-1/2 md:tw-mx-8">
          <TextFieldComponent
              :error="!!validation_form.covert_number"
              class="md:tw-w-1/3 tw-w-1/2"
              v-model="command_form.covert_number"
              title="Nombre de couverts en moyenne/jour*"
              label="000000"
          />
          <p class="radio-field-title">
            Renseignez ici, si votre établissement possède plusieurs restaurants.
          </p>
          <div
              class="radio-container tw-flex tw-items-center tw-justify-between tw-cursor-pointer"
              :class="command_form.multi_restaurant ? 'tw-bg-primary-cniel' : 'tw-bg-white'"
              @click="displayRadioHandler"
          >
            <img v-if="!command_form.multi_restaurant" src="@/assets/images/radio-icon.svg" alt="">
            <span
                v-if="!command_form.multi_restaurant" class="radio-title tw-text-primary-cniel">Non</span>

            <span v-if="command_form.multi_restaurant" class="radio-title tw-text-white">Oui</span>
            <img v-if="command_form.multi_restaurant" src="@/assets/images/radio-icon.svg" alt="">
          </div>
          <div v-if="command_form.multi_restaurant" class="radio-desc">
            <p class="radio-desc-text">
              Vous avez sélectionné oui, nous déclenchons pour votre établissement
              l’envoi de 2 kits de communication. Si vous ne souhaitez pas recevoir 2 kits,
              sélectionnez non.
            </p>
          </div>
        </div>
      </div>
    </v-container>
    <LineCniel class="tw-mx-12"/>
    <v-container class="command-form-container">
      <div class="tw-flex tw-flex-col tw-space-y-3">
        <h5 class="form-guide-title"
            :style="validation_form.fortnight_check ? 'color: #FB4949' : ''"
        >
          Quand souhaitez-vous activer la campagne ?*</h5>
        <div class="tw-cursor-pointer tw-flex tw-flex-row tw-items-center tw-space-x-3" @click="octoberHandelClick">
          <img v-if="command_form.fortnight_check=== 'october'" src="@/assets/images/button-radio-true.svg" alt="">
          <img v-else src="@/assets/images/button-radio-false.svg" alt="">
          <span class="buton-radio-text">
            Première quinzaine d’octobre
          </span>
        </div>
        <div class="tw-cursor-pointer tw-flex tw-flex-row tw-items-center tw-space-x-3" @click="novemberHandelClick">
          <img v-if="command_form.fortnight_check=== 'november'" src="@/assets/images/button-radio-true.svg" alt="">
          <img v-else src="@/assets/images/button-radio-false.svg" alt="">
          <span class="buton-radio-text">
            Première quinzaine de novembre
          </span>
        </div>
      </div>
    </v-container>
    <LineCniel class="tw-mx-12" />
    <v-container class="command-form-container">
      <v-checkbox class="checkbox-form" color="#151F6D" v-model="command_form.accept_cgu" >
        <template v-slot:label>
          <div class="required-fields-text"
               :style="validation_form.accept_cgu ? 'color: #FB4949' : ''"
          >
            En cochant cette case, vous certifiez avoir lu et accepté sans réserve
            les présentes conditions générales d’utilisations >
            <a
                style="color: #151F6D"
                :style="validation_form.accept_cgu ? 'color: #FB4949' : ''"
                class="tw-underline tw-font-bold"
                href="./download/CGU.pdf"
                @click.stop
                download>
              CGU.*
            </a>
          </div>
        </template>
      </v-checkbox>
      <v-checkbox class="checkbox-form" color="#151F6D" v-model="command_form.accept_condition">
        <template v-slot:label>
          <div class="required-fields-text"
               :style="validation_form.accept_condition ? 'color: #FB4949' : ''"
          >
            En cochant cette case, vous certifiez être informé(e) et acceptez la Politique de protection des données personnelles
            et les traitements réalisés par le CNIEL sur les données me concernant.
             Pour en savoir plus et pour exercer vos droits,
            reportez-vous à la notice ci-jointe >
            <a
                style="color: #151F6D"
                :style="validation_form.accept_condition ? 'color: #FB4949' : ''"
                class="tw-underline tw-font-bold"
                href="./download/Politique de protection des données personnelles.pdf"
                @click.stop
                download>
              Gestion de vos données personnelles.*
            </a>
          </div>
        </template>
      </v-checkbox>
    </v-container>
    <LineCniel class="tw-mx-12" />
    <v-container class="command-form-container">
      <div class="tw-flex tw-flex-col tw-items-center">
        <span class="required-fields-text">*Champs obligatoires.</span>
        <span v-if="formHasError" class="error-fields-text">Vous devez renseignez tous les champs oblogatoires.</span>
        <PrimaryButtonComponent
            :loading="isSendingForm"
            @click="submit"
            class="submit-button"
        >
          Valider ma commande
        </PrimaryButtonComponent>
      </div>
    </v-container>
  </div>
</template>

<script>
import PrimaryTitle from "../../../components/common/PrimaryTitle";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import LineCniel from "../../../components/common/LineCniel";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import {postOrder} from "../services/appApi";
export default {
  name: "CommandForm",
  components: {PrimaryButtonComponent, LineCniel, TextFieldComponent, SelectFieldComponent, PrimaryTitle},
  
  data() {
    return {
      genderItems: ['Mr', 'Mme'],
      typeItems: ['Primaire', 'Collège', 'Lycée'],
      isSendingForm: false,
      sentModal: false,
      existOrderModal: false,
      formHasError: false,
      command_form: {
        gender: 'Mr',
        lastName: '',
        firstName: '',
        email: '',
        type: 'Collège',
        department: '',
        department_address: '',
        compl_info: '',
        postal_code: '',
        city: '',
        covert_number: '',
        multi_restaurant: false,
        fortnight_check: 'october',
        accept_cgu: false,
        accept_condition: false,
      },
      validation_form: {
        gender: null,
        lastName: null,
        firstName: null,
        email: null,
        type: null,
        department: null,
        department_address: null,
        compl_info: null,
        postal_code: null,
        city: null,
        covert_number: null,
        multi_restaurant: false,
        fortnight_check: null,
        accept_cgu: null,
        accept_condition: null,
      },
    }
  },

  methods: {
    displayRadioHandler() {
      this.command_form.multi_restaurant = !this.command_form.multi_restaurant;
    },
    novemberHandelClick() {
      this.command_form.fortnight_check = 'november';
    },
    octoberHandelClick() {
      this.command_form.fortnight_check = 'october';
    },

    submit() {
      this.reset_validation();
      this.isSendingForm = true;
      let data = this.command_form;
      if (data.compl_info === "") {
        delete data.compl_info;
      }
      postOrder(data)
        .then(resp => {
          if (resp.data.message === 'department has an order') {
            this.existOrderModal = true;
          }else {
            this.sentModal = true;
          }
          this.isSendingForm = false;
        })
        .catch(({response}) => {
          this.validation_form = response.data.errors;
          response.data.errors ? this.formHasError = true : false;
          this.isSendingForm = false;
        });

    },

    reset_validation() {
      this.formHasError = false;
      this.validation_form = {
        gender: null,
        lastName: null,
        firstName: null,
        email: null,
        type: null,
        department: null,
        department_address: null,
        compl_info: null,
        postal_code: null,
        city: null,
        covert_number: null,
        multi_restaurant: false,
        fortnight_check: null,
        contact: null,

      };
    },

    button_retour_modal() {
      this.command_form = {
        gender: 'Mr',
        lastName: '',
        firstName: '',
        email: '',
        type: 'Collège',
        department: '',
        department_address: '',
        compl_info: '',
        postal_code: '',
        city: '',
        covert_number: '',
        multi_restaurant: false,
        fortnight_check: 'october',
        accept_cgu: false,
        accept_condition: false,
      };
      this.sentModal = false;
      this.reset_validation();
      window.scrollTo(0,0);
    }
  }

}
</script>

<style scoped>

</style>