<template>
  <div class="tw-flex tw-flex-col">
    <span class="text-field-title">{{title}}</span>
    <p>
      <input
          :class="controlInput"
          type="text"
          :placeholder="label"
          :value="value"
          v-on:input="updateValue($event.target.value)">
    </p>
  </div>
</template>

<script>
export default {
  name: "TextFieldComponent",
  computed: {
    controlInput() {
      if (this.error) {
        return 'text-field-error';
      }
      else {
        if (this.value && this.value.length > 0) {
          return 'text-field-with-data';
        }
        else {
          return 'text-field';
        }
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String
    },
    error: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>