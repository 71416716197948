<template>
  <div class="tw-flex tw-flex-col">
    <span class="select-field-title">{{title}}</span>
    <select
        :class="controlInput"
        type="text"
        :placeholder="label"
        :value="value"
        v-on:change="updateValue($event.target.value)"
    >
      <option v-for="(item, index) in items" :key="index" :value="item">{{item}}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SelectFieldComponent",
  computed: {
    controlInput() {
      if (this.error) {
        return 'fa-error';
      }
      else {
        if (this.value.length > 0) {
          return 'fa-with-data';
        }
        else {
          return 'fa-with-data';
        }
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String
    },
    items: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style scoped>

</style>