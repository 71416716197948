<template>
  <div class="tw-flex tw-flex-col">
    <span class="textarea-field-title">{{title}}</span>
    <textarea
        :class="controlInput"
        :placeholder="label"
        :value="value"
        v-on:input="updateValue($event.target.value)"
    >
    </textarea>
    <span class="textarea-field-desc">{{desc}}</span>
  </div>
</template>

<script>
export default {
  name: "TextAreaComponent",

  computed: {
    controlInput() {
      if (this.error) {
        return 'text-area-error';
      }
      else {
        if (this.value.length > 0) {
          return 'text-area-with-data';
        }
        else {
          return 'text-area';
        }
      }
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String
    },
    desc: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    }
  }
}
</script>