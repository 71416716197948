<template>
  <v-container id="platform-digital">
    <div class="tw-flex tw-flex-col md:tw-flex-row platform-digital-container">
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-between md:tw-w-1/2">
        <img src="@/assets/images/platform-degital1.jpg" class="platform-digital-image" alt="guide acheteur" />
        <h5 class="platform-digital-title">Répondre aux enjeux de la restauration collective</h5>
        <p class="platform-digital-text">
          Téléchargez notre <strong>guide acheteur</strong> ainsi qu’un
          <strong>livret de recettes</strong> pour vous accompagner.
        </p>
        <a href="#" v-scroll-to="'#restauration'">
          <PrimaryButtonComponent class="platform-digital-button">
            Accéder
          </PrimaryButtonComponent>
        </a>
      </div>
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-mt-4 md:tw-mt-0 md:tw-w-1/2">
        <img src="@/assets/images/platform-degital2.jpg" class="platform-digital-image" alt="commandez gratuitement" />
        <h5 class="platform-digital-title">
          Animez vos restaurants pour sensibiliser vos élèves aux produits laitiers
        </h5>
        <p class="platform-digital-text">
          <strong>Commandez gratuitement</strong> nos outils de communication
        </p>
        <PrimaryButtonComponent @click="go_to_kit_communication" class="platform-digital-button">
          Découvrir
        </PrimaryButtonComponent>
      </div>
    </div>
  </v-container>
</template>

<script>
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
export default {
  name: "PlatformDigital",
  components: {PrimaryButtonComponent},
  methods: {
    go_to_kit_communication() {
      this.$router.push({ name: 'Public.Order' });
    }
  }
}
</script>

<style scoped>

</style>