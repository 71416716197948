import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import router from "./routes/router"
import PerfectScrollbar from "vue2-perfect-scrollbar"
import vuetifyPlugin from "./plugins/vuetify"
import snackbarPlugin from "./plugins/snackbar"
import store from "./store"
import Notifications from "vue-notification"
import {serverConfig} from "@/config/serverConfig"
import ApiService from "@/services/api.service"

import "./assets/styles/vutify-theme/app.scss"

var VueScrollTo = require('vue-scrollto');

ApiService.init();

Vue.prototype.$app_url = serverConfig.appUrl;
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(snackbarPlugin, { store });
Vue.use(PerfectScrollbar);
Vue.use(Notifications);
Vue.use(VueScrollTo);

new Vue({
  store,
  vuetify: vuetifyPlugin,
  router,
  render: (h) => h(App),
}).$mount("#app");
