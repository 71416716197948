<template>
  <div class="tw-flex tw-flex-col from-container">
    <v-dialog
        overlay-color="#151F6D"
        v-model="sentModal"
        persistent
        transition="dialog-top-transition"
        width="70%"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <p class="form-modal-title">
          Merci pour votre participation au programme<br>
          “Les produits laitiers & la restauration scolaire”.
        </p>
        <p class="form-modal-text">
          Vos contacts et vous-même venez de recevoir par e-mail, “Les recettes du chef” et “Le Guide acheteur”.
        </p>
        <PrimaryButtonComponent @click="retour_modal_button" class="form-modal-button">
          Retour
        </PrimaryButtonComponent>
      </div>
    </v-dialog>
    <div class="tw-flex tw-flex-col md:tw-flex-row">
      <div class="tw-flex tw-flex-col md:tw-w-1/2 mdtw-mx-8">
        <h5 class="form-guide-title">Vos coordonnées :</h5>
        <SelectFieldComponent
            :error="!!validation_form.gender"
            class="tw-w-1/2 md:tw-w-1/3"
            :items="genderItems"
            v-model="guide_form.gender"
            title="Civilité*"
            label="Mr"
        />
        <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-6">
          <TextFieldComponent
              :error="!!validation_form.lastName"
              class="tw-w-full"
              v-model="guide_form.lastName"
              title="Votre nom*"
              label="Doe"
          />
          <TextFieldComponent
              :error="!!validation_form.firstName"
              class="tw-w-full"
              v-model="guide_form.firstName"
              title="Votre prénom*"
              label="John"
          />
        </div>
        <TextFieldComponent
            :error="!!validation_form.email"
            class="tw-w-full"
            v-model="guide_form.email"
            title="Votre e-mail*"
            label="jdoe@email.com"
        />
      </div>
      <div class="tw-flex tw-flex-col md:tw-w-1/2 md:tw-mx-8">
        <h5 class="form-guide-title">Vos contacts :</h5>
        <p class="guide-contact-text">
          Renseignez ici les adresses e-mail de vos contacts en les séparant par des virgules,
          dans la limite de 5 contacts.
        </p>
          <TextAreaComponent
              :error="!!validation_form.contact"
              v-model="guide_form.contact"
              title="Vos contacts* :"
              desc="Les Données Personnelles que vous communiquez au CNIEL et à New Venise via le
              formulaire de demande de transfert d’information ne sont utilisées qu’une seule fois
              à des fins de communication et de gestion de la demande de transfert, et conservées
              pour une durée d’un an."
              label="nomprenom@gmail.com,nomprenom@gmail.com,nomprenom@gmail.com,nomprenom@gmail.com,nomprenom@gmail.com"
          />
      </div>
    </div>
    <span class="required-fields-text">*Champs obligatoires.</span>
    <LineCniel />
    <v-checkbox class="checkbox-form" color="#151F6D" v-model="guide_form.accept_program" >
      <template v-slot:label>
        <div class="required-fields-text"
        :style="validation_form.accept_program ? 'color: #FB4949' : ''"
        >
          J’accepte de recevoir les informations sur le programme
          “Les produits laitiers et la restauration scolaire”.*
        </div>
      </template>
    </v-checkbox>
    <v-checkbox class="checkbox-form" color="#151F6D" v-model="guide_form.accept_condition">
      <template v-slot:label>
        <div class="required-fields-text"
             :style="validation_form.accept_condition ? 'color: #FB4949' : ''"
        >
          En cochant cette case, vous certifiez être informé(e) et acceptez la Politique
          de protection des données personnelles et les traitements réalisés par
          le CNIEL sur les données me concernant. Pour en savoir plus et pour exercer vos droits,
          reportez-vous à la notice ci-jointe >
            <a
                style="color: #151F6D"
                :style="validation_form.accept_condition ? 'color: #FB4949' : ''"
                class="tw-underline tw-font-bold"
                href="./download/Politique de protection des données personnelles.pdf"
                @click.stop
                download>
              Gestion de vos données personnelles.*
            </a>
        </div>
      </template>
    </v-checkbox>
    <LineCniel />
    <span v-if="formHasError" class="error-fields-text">Vous devez renseignez tous les champs oblogatoires.</span>
    <PrimaryButtonComponent
        :loading="isSendingForm"
        @click="submit"
        class="submit-button"
    >
      Envoyer
    </PrimaryButtonComponent>
  </div>
</template>

<script>
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import TextAreaComponent from "../../../components/common/TextAreaComponent";
import LineCniel from "../../../components/common/LineCniel";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import {transfertGuideAndRecettes} from "../services/appApi";
export default {
  name: "GuideForm",
  components: {PrimaryButtonComponent, LineCniel, TextAreaComponent, SelectFieldComponent, TextFieldComponent},
  data() {
    return {
      genderItems: ['Mr', 'Mme'],
      isSendingForm: false,
      sentModal: false,
      formHasError: false,
      guide_form: {
        gender: 'Mr',
        lastName: '',
        firstName: '',
        email: '',
        contact: '',
        accept_program: false,
        accept_condition: false,
      },
      validation_form: {
        gender: null,
        lastName: null,
        firstName: null,
        email: null,
        contact: null,
        accept_program: null,
        accept_condition: null,
      },
    }
  },
  methods: {
    submit() {
      this.isSendingForm = true;
      this.reset_validation();
      transfertGuideAndRecettes(this.guide_form)
      // eslint-disable-next-line no-unused-vars
      .then(resp => {
        this.isSendingForm = false;
        this.sentModal = true;
      })
      .catch(({response}) => {
        this.validation_form = response.data.errors;
        response.data.errors ? this.formHasError = true : false;
        this.isSendingForm = false;
      });
    },
    reset_validation() {
      this.formHasError = false;
      this.validation_form = {
        gender: null,
        lastName: null,
        firstName: null,
        email: null,
        contact: null,
        accept_program: null,
        accept_condition: null,
      };
    },
    retour_modal_button() {
      this.guide_form = {
        gender: 'Mr',
            lastName: '',
            firstName: '',
            email: '',
            contact: '',
            accept_program: false,
            accept_condition: false,
      };
      this.sentModal = false;
      this.reset_validation();
      this.$emit('closeForm', '');
      window.scrollTo(0,0);
    }
  }
}
</script>

<style scoped>

</style>