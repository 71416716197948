<template>
  <div>
    <perfect-scrollbar
        ref="scroll"
        style="height: 380px"
    >
      <p class="form-modal-title">
        Politique de protection des données personnelles
      </p>
      <p class="tw-text-justify">
        Publiée le <strong>21/06/2021,</strong>
      </p>
      <p class="tw-text-justify">
        Le CNIEL collecte des informations vous concernant et/ou des données personnelles
        (ci-après désignées les « Données Personnelles ») via son site internet
        produitslaitiersrestaurationscolaire.fr
      </p>
      <p class="tw-mt-2 tw-font-bold">
        RECUEIL DES DONNEES PERSONNELLES
      </p>
      <p class="tw-text-justify">
        Les informations recueillies par votre utilisation du site web
        produitslaitiersrestaurationscolaire.fr sont strictement destinées au CNIEL et à
        son sous-traitant New Venise dans le cadre de la campagne « l’Europe a du bon » en
        vue de la réalisation des traitements suivants :
      </p>
      <ul>
        <li class="tw-font-bold"><span class="tw-mr-3">•</span>	Formulaires de contact</li>
      </ul>
      <p class="tw-text-justify">
        Les Données Personnelles que vous communiquez au CNIEL et à New Venise via le
        formulaire de contact ne sont utilisées qu’à des fins de gestion de votre demande,
        et conservées pour une durée d’un an.
      </p>
      <ul>
        <li class="tw-font-bold"><span class="tw-mr-3">•</span>Formulaires de transfert d’informations : </li>
      </ul>
      <p class="tw-text-justify">
        Les Données Personnelles que vous communiquez au CNIEL et à New Venise via le
        formulaire de demande de transfert d’information ne sont utilisées qu’une seule fois à des fins de
        communication et de gestion de votre demande de transfert, et conservées pour une durée d’un an.
      </p>
      <ul>
        <li class="tw-font-bold"><span class="tw-mr-3">•</span>La gestion de vos commandes : </li>
      </ul>
      <p class="tw-text-justify">
        Les Données Personnelles que vous communiquez au CNIEL et New Venise pour la passation
        de vos commandes réalisées via le formulaire de commande ne sont utilisées
        qu’à des fins de communication et de gestion de votre commande du kit de communication,
        et conservées pour une durée d’un an.
      </p>
      <p class="tw-mt-2 tw-font-bold">
        Participation au Challenge « la plus belle semaine du lait » :
      </p>
      <p class="tw-text-justify">
        Les Données Personnelles que vous communiquez au CNIEL et New Venise pour la participation au
        challenge via le formulaire de dépôt de photos ne sont utilisées qu’à des fins de gestion du
        challenge, et conservées pour une durée d’un an.
      </p>
      <p class="tw-mt-2 tw-font-bold">
        CONFIDENTIALITE
      </p>
      <p class="tw-text-justify">
        Le CNIEL et New Venise mettent en œuvre toutes les mesures techniques et organisationnelles
        nécessaires pour garantir la sécurité et la confidentialité des Données Personnelles
        collectées et traitées, et notamment empêcher qu’elles soient déformées, endommagées ou
        communiquées à des Tiers non autorisés, en assurant un niveau de sécurité adapté aux risques
        liés au traitement et à la nature des données à protéger, eu égard au niveau technologique et
        au coût de mise en œuvre.
      </p>
      <p class="tw-text-justify">
        En vertu de la législation applicable à la protection des données à caractère personnel,
        vous disposez d’un droit d’accès, de modification, de rectification et de suppression des
        données qui vous concernent. Vos demandes doivent être adressées par courriel à l’adresse
        suivante :  contact@veniseactivation.
      </p>
      <p class="tw-text-justify">
        Vous disposez également de la possibilité d’introduire une réclamation auprès de
        la Commission Nationale de l’Informatique et des Libertés (CNIL).
      </p>
      <p class="tw-mt-2 tw-font-bold">
        POLITIQUE DE GESTION DES COOKIES
      </p>
      <p class="tw-mt-2 tw-font-bold">
        Cookies
      </p>
      <p class="tw-text-justify">
        Les cookies sont de petits fichiers de données envoyées par un site internet et qui
        sont stockés sur votre navigateur internet lorsque vous visitez un site internet.
        Ils permettent de sauvegarder temporairement des informations anonymes concernant les
        visites et visiteurs du site. A chaque visite d’un internaute sur le site, les données
        de sa précédente visite sont récupérées.
      </p>
      <p class="tw-text-justify">
        Certains cookies sont strictement nécessaires à l’utilisation du site.
      </p>
      <p class="tw-text-justify">
        D’autres cookies ou technologies similaires, déposés par le CNIEL ou des tiers
        permettent de personnaliser et d’optimiser l’affichage de certains contenus,
        de conserver vos préférences, de mesurer la performance du site, de sécuriser l’accès
        au site, de partager du contenu, et de détecter des anomalies.
      </p>
      <p class="tw-mt-2 tw-font-bold">
        Utilisation des cookies par le CNIEL
      </p>
      <p>
        <span class="tw-mr-3">1.</span>	Les cookies fonctionnels
      </p>
      <p class="tw-text-justify">
        Les cookies fonctionnels sont nécessaires au fonctionnement du site internet.
      </p>

      <p class="tw-text-justify">
        Les cookies fonctionnels sont indispensables au fonctionnement des sites du CNIEL
        et à la navigation des utilisateurs sur ces derniers. Ils permettent aux personnes
        d'utiliser les principales fonctionnalités des sites et de sécuriser leur connexion.
        Ils peuvent leur permettre également d'accéder directement à des espaces qui leur sont
        réservés, grâce à des identifiants ou des données qu’elles ont éventuellement antérieurement
        confiées au CNIEL.
      </p>
      <p class="tw-text-justify">
        Ces cookies ont une durée de vie très courte, le temps que la session reste active.
      </p>
      <p class="tw-text-justify">
        Sans ces cookies, les utilisateurs ne pourront pas utiliser normalement les sites et
        il est déconseillé par conséquent d’empêcher leur utilisation ou de les supprimer.
      </p>
      <table class="table-gris">
        <tr class="table-gris-tr">
          <td class="table-gris-td">Nom</td>
          <td class="table-gris-td">Finalité</td>
          <td class="table-gris-td">Durée</td>
        </tr>
        <tr class="table-gris-tr">
          <td class="table-gris-td">Stockage web local</td>
          <td class="table-gris-td">Permet de retenir vos préférences (particulier ou professionnel)</td>
          <td class="table-gris-td">Session</td>
        </tr>
      </table>
      <p class="tw-mt-4">
        <span class="tw-mr-3">2.</span>	Les cookies de mesure d’audience
      </p>
      <p class="tw-text-justify">
        Les cookies de mesure d’audience et de statistique permettent au CNIEL de connaître
        l'utilisation et les performances des sites, d'établir des statistiques, des volumes
        de fréquentation et d'utilisation de divers éléments (contenus visités, parcours…) aux
        fins d'améliorer l'intérêt et l'ergonomie des services proposés (les pages ou les rubriques
        les plus souvent consultées, les articles les plus lus ...). Ces cookies servent également à
        mesurer le trafic de consultation du site internet.
      </p>
      <p class="tw-text-justify">
        Pour certains de ces cookies, l’adresse IP, seule donnée liée à l’identification des
        visiteurs des Sites dans ce cadre, fait l’objet d’une anonymisation avec suppression du dernier octet.
      </p>
      <p class="tw-text-justify">
        La durée de vie des cookies de mesure d’audience est limitée à treize mois.
      </p>
      <p class="tw-text-justify">
        Le fait de refuser la mise en œuvre de tels cookies ou de les supprimer n'a pas
        d'incidence sur la navigation des personnes sur les sites, mais est de nature à
        empêcher le CNIEL de s’assurer de l’amélioration de la qualité de services proposés
        aux visiteurs de ses sites.
      </p>
      <table class="table-black">
        <tr class="table-black-tr">
          <td class="table-black-td">Nom du cookie</td>
          <td class="table-black-td">Finalité</td>
          <td class="table-black-td">Durée de conservation</td>
        </tr>
        <tr class="table-black-tr">
          <td class="table-black-td">_ga</td>
          <td class="table-black-td">
            Ce cookie interne créé par Google Analytics est utilisé par Google Analytics pour
            distinguer les utilisateurs lors de collectes d’informations sur les pages visitées.
            Ce cookie nous aide à identifier les éléments à améliorer sur notre site Internet.
            D’autre part le cookie _ga ne permet pas au Cniel d’identifier l’utilisateur personnellement,
            ni d’obtenir des renseignements ou des données personnelles à son sujet.
          </td>
          <td class="table-black-td">13 mois</td>
        </tr>
        <tr class="table-black-tr">
          <td class="table-black-td">_gid</td>
          <td class="table-black-td">
            Ce cookie interne créé par Google Analytics est utilisé par Google Analytics
            pour distinguer les utilisateurs lors de collectes d’informations sur les pages
            visitées. Ce cookie nous aide à identifier les éléments à améliorer sur notre site
            Internet. D’autre part le cookie _ga ne permet pas au Cniel d’identifier l’utilisateur
            personnellement, ni d’obtenir des renseignements ou des données personnelles à son sujet.
          </td>
          <td class="table-black-td">24 heures</td>
        </tr>
        <tr class="table-black-tr">
          <td class="table-black-td">_gat</td>
          <td class="table-black-td">
            Ce cookie interne est créé par Google Analytics et est utilisé par
            Google Analytics pour accélérer le rythme des requêtes.
          </td>
          <td class="table-black-td">1 minute</td>
        </tr>
      </table>
      <p class="tw-text-justify">
        Politique de confidentialité Google : https://policies.google.com/privacy?hl=fr
      </p>
      <p class="tw-text-justify">
        Le site s’appuie sur ce tiers :
      </p>
      <ul>
        <li><span class="tw-mr-3">•</span>Google Analytics : un service d'analyse d’audience de site internet fourni par Google Inc. (« Google ») </li>
      </ul>
      <p class="tw-text-justify">
        Ce tiers est listé dans la fenêtre de gestion des cookies.
      </p>
      <p class="tw-text-justify">
        Cette fonctionnalité utilise des cookies tiers directement déposés par ce service.
      </p>
      <p class="tw-text-justify">
        Via ces cookies, ce tiers collectera et utilisera également vos données de navigation pour
        son propre compte afin de proposer de la publicité ciblée et des contenus en fonction de
        votre historique de navigation. Pour plus d’informations, nous vous encourageons à consulter
        sa politique de confidentialité.
      </p>
      <p class="tw-mt-2 tw-font-bold">
        Gestion de vos choix de cookies :
      </p>
      <p class="tw-text-justify">
        <strong>Par défaut, le refus est supposé et aucun traceur n'est déposé. Vous pouvez consentir au dépôt </strong>
        des traceurs dans la fenêtre de [Gestion des cookies] présente en bas de chaque page du site.
      </p>
      <p class="tw-text-justify">
        Par ailleurs, pour la gestion des cookies et de vos choix, la configuration de
        chaque navigateur est différente. Elle est décrite dans le menu d'aide de votre navigateur,
        qui vous permettra de savoir de quelle manière modifier vos préférences en matière
        de cookies. Par exemple :
      </p>
      <p class="tw-text-justify">
        Pour Internet Explorer™ : http://windows.microsoft.com/fr-fr/windows7/block-enable-or-allow-cookies<br>
        Pour Safari™ : http://support.apple.com/kb/PH19214?viewlocale=fr_FR<br>
        Pour Chrome™: http://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647<br>
        Pour Firefox™ : http://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies<br>
        Pour Opera™ : http://help.opera.com/Windows/10.20/fr/cookies.html<br>
      </p>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "Politique"
}
</script>

<style scoped>
.table-gris {
  border: 3px solid #E8E5DC;
}
.table-gris-tr {
  border: 3px solid #E8E5DC;
}
.table-gris-td {
  border: 3px solid #E8E5DC;
  padding: 10px;
}

.table-black {
  border: 1px solid #000000;
}
.table-black-tr {
  border: 1px solid #000000;
}
.table-black-td {
  width: 33%;
  border: 1px solid #000000;
  padding: 3px;
}

</style>