<template>
  <div class="tw-flex tw-flex-col from-container">
    <v-dialog
        overlay-color="#151F6D"
        v-model="sentModal"
        persistent
        transition="dialog-top-transition"
        width="70%"
    >
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <p class="form-modal-title">
          Merci pour votre participation au programme<br>
          “Les produits laitiers & la restauration scolaire”.
        </p>
        <p class="form-modal-text">
          Votre contact vient de recevoir l’e-mail d’information, vous allez recevoir une copie de celui-ci.
        </p>
        <PrimaryButtonComponent @click="retour_modal_button" class="form-modal-button">
          Retour
        </PrimaryButtonComponent>
      </div>

    </v-dialog>
    <div class="tw-flex tw-flex-col md:tw-flex-row">
      <div class="tw-flex tw-flex-col tw-justify-between md:tw-w-1/2 md:tw-mx-8">
        <h5 class="form-guide-title">Vous :</h5>
        <p class="guide-contact-text">
          Renseignez ici vos coordonnées.
        </p>
        <SelectFieldComponent
            :error="!!validation_form.gender"
            class="tw-w-1/2 md:tw-w-1/3"
            :items="genderItems"
            v-model="contact_form.gender"
            title="Civilité*"
            label="Mr"
        />
        <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-6">
          <TextFieldComponent
              :error="!!validation_form.lastName"
              class="tw-w-full"
              v-model="contact_form.lastName"
              title="Votre nom*"
              label="Doe"
          />
          <TextFieldComponent
              :error="!!validation_form.firstName"
              class="tw-w-full"
              v-model="contact_form.firstName"
              title="Votre prénom*"
              label="John"
          />
        </div>
        <TextFieldComponent
            :error="!!validation_form.email"
            class="tw-w-full"
            v-model="contact_form.email"
            title="Votre e-mail*"
            label="jdoe@email.com"
        />
      </div>
      <div class="tw-flex tw-flex-col tw-justify-between md:tw-w-1/2 md:tw-mx-8">
        <h5 class="form-guide-title">Votre contact :</h5>
        <p class="guide-contact-text">
          Renseignez ici les coordonnées de la personne habilitée à
          réaliser ce type de commande, un e-mail lui sera envoyé.
        </p>
        <SelectFieldComponent
            :error="!!validation_form.contact_gender"
            class="tw-w-1/2 md:tw-w-1/3"
            :items="genderItems"
            v-model="contact_form.contact_gender"
            title="Civilité*"
            label="Mr"
        />
        <div class="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-6">
          <TextFieldComponent
              :error="!!validation_form.contact_lastName"
              class="tw-w-full"
              v-model="contact_form.contact_lastName"
              title="Nom*"
              label="Doe"
          />
          <TextFieldComponent
              :error="!!validation_form.contact_firstName"
              class="tw-w-full"
              v-model="contact_form.contact_firstName"
              title="Prénom*"
              label="John"
          />
        </div>
        <div class="tw-flex tw-flex-col">
          <TextFieldComponent
              :error="!!validation_form.contact_email"
              class="tw-w-full"
              v-model="contact_form.contact_email"
              title="E-mail*"
              label="mail@email.com"
          />

        </div>

      </div>
    </div>
    <div class="tw-flex">
      <div class="tw-flex tw-flex-col tw-justify-between md:tw-w-1/2 md:tw-mx-8"></div>
      <div class="tw-flex tw-flex-col tw-justify-between md:tw-w-1/2 md:tw-mx-8">
        <p class="email-field-desc">
          Les Données Personnelles que vous communiquez au CNIEL et à New Venise via le
          formulaire de demande de transfert d’information ne sont utilisées qu’une seule fois
          à des fins de communication et de gestion de la demande de transfert, et conservées
          pour une durée d’un an.
        </p>
      </div>
    </div>

    <span class="required-fields-text">*Champs obligatoires.</span>
    <LineCniel />
    <v-checkbox class="checkbox-form" color="#151F6D" v-model="contact_form.accept_program" >
      <template v-slot:label>
        <div class="required-fields-text"
             :style="validation_form.accept_program ? 'color: #FB4949' : ''"
        >
          J’accepte de recevoir les informations sur le programme
          “Les produits laitiers et la restauration scolaire”.*
        </div>
      </template>
    </v-checkbox>
    <v-checkbox class="checkbox-form" color="#151F6D" v-model="contact_form.accept_condition">
      <template v-slot:label>
        <div class="required-fields-text"
             :style="validation_form.accept_condition ? 'color: #FB4949' : ''"
        >
          En cochant cette case, vous certifiez être informé(e) et acceptez
          la Politique de protection des données personnelles
           et les traitements réalisés par le CNIEL sur les données me concernant.
          Pour en savoir plus et pour exercer vos droits,
          reportez-vous à la notice ci-jointe >
          <a
              style="color: #151F6D"
              :style="validation_form.accept_condition ? 'color: #FB4949' : ''"
              class="tw-underline tw-font-bold"
              href="./download/Politique de protection des données personnelles.pdf"
              @click.stop
              download>
            Gestion de vos données personnelles.*
          </a>
        </div>
      </template>
    </v-checkbox>
    <LineCniel />
    <span v-if="formHasError" class="error-fields-text">Vous devez renseignez tous les champs oblogatoires.</span>
    <PrimaryButtonComponent
        @click="submit"
        class="submit-button"
        :loading="isSendingForm"
    >
      Envoyer
    </PrimaryButtonComponent>
  </div>
</template>

<script>
import TextFieldComponent from "../../../components/common/TextFieldComponent";
import SelectFieldComponent from "../../../components/common/SelectFieldComponent";
import LineCniel from "../../../components/common/LineCniel";
import PrimaryButtonComponent from "../../../components/common/PrimaryButtonComponent";
import {transferOrder} from "../services/appApi";
export default {
  name: "TransfertForm",
  components: {PrimaryButtonComponent, LineCniel, SelectFieldComponent, TextFieldComponent},
  data() {
    return {
      genderItems: ['Mr', 'Mme'],
      sentModal: false,
      formHasError: false,
      isSendingForm: false,
      contact_form: {
        gender: 'Mr',
        lastName: '',
        firstName: '',
        email: '',
        contact_gender: 'Mr',
        contact_lastName: '',
        contact_firstName: '',
        contact_email: '',
        accept_program: false,
        accept_condition: false,
      },
      validation_form: {
        gender: null,
        lastName: null,
        firstName: null,
        email: null,
        contact_gender: null,
        contact_lastName: null,
        contact_firstName: null,
        contact_email: null,
        accept_program: null,
        accept_condition: null,
      },

    }
  },
  methods: {
    submit() {
      this.reset_validation();
      this.isSendingForm = true;
      transferOrder(this.contact_form)
        .then(resp => {
          console.log(resp);
          this.sentModal = true;
          this.isSendingForm = false;
        })
        .catch(({response}) => {
          this.validation_form = response.data.errors;
          response.data.errors ? this.formHasError = true : false;
          this.isSendingForm = false;
        });
    },
    retour_modal_button() {
      this.contact_form =  {
        gender: 'Mr',
            lastName: '',
            firstName: '',
            email: '',
            contact_gender: 'Mr',
            contact_lastName: '',
            contact_firstName: '',
            contact_email: '',
            accept_program: false,
            accept_condition: false,
      };
      this.sentModal = false;
      this.reset_validation();
      this.$emit('closeForm', '');
      window.scrollTo(0,0);
    },
    reset_validation() {
      this.formHasError = false;
      this.validation_form = {
        gender: null,
        lastName: null,
        firstName: null,
        email: null,
        contact_gender: null,
        contact_lastName: null,
        contact_firstName: null,
        contact_email: null,
        accept_program: null,
        accept_condition: null,
      };
    },
  }
}
</script>

<style scoped>

</style>