<template>
  <v-container id="support-detail">
    <div class="support-detail-container">
      <PrimaryTitle title="Détails des supports" />
      <div class="tw-hidden md:tw-flex md:tw-flex-row tw-w-full">
        <div class="tw-flex tw-flex-col tw-justify-between tw-w-1/3">
          <div>
            <span class="tw-ml-2 contenu-text">Contenu du kit :</span>
            <ul class="tw-mt-6 tw-list-disc">
              <li class="contenu-kit-li">Affiche 1 - x3**</li>
              <li class="contenu-kit-li">Affiche 2 - x3**</li>
              <li class="contenu-kit-li">Chevalet de Comptoir - x2*</li>
              <li class="contenu-kit-li">Lot de mini-chevalets - x9*</li>
              <li class="contenu-kit-li">Livret Recettes du Chef  - x1</li>
              <li class="contenu-kit-li">Totem - x1</li>
            </ul>
          </div>
          <div class="tw-ml-2">
            <span class="contenu-kit-detail">
              * Encre alimentaire. Les encres mis au contact des denrées alimentaires
              sont inertes vis-à-vis des denrées alimentaires. <br>
              ** pastilles double face mousse 4 coins.
            </span>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-w-2/3">
          <div class="tw-flex tw-justify-between">
            <SupportItemCard
                support-img="support1.jpg"
                support-title="Affiche 1**"
                support-text="Taille : 60x80"
            />
            <SupportItemCard
                support-img="support2.jpg"
                support-title="Affiche 2**"
                support-text="Taille : 60x80"
            />
            <SupportItemCard
                support-img="support3.jpg"
                support-title="Chevalet de Comptoir*"
                support-text="Taille : 21x29,7"
            />
          </div>
          <div class="tw-flex tw-justify-between tw-mt-4">
            <SupportItemCard
                support-img="support4.jpg"
                support-title="Lot de mini-chevalets"
                support-text="Taille : 10x7"
            />
            <SupportItemCard
                support-img="support5.jpg"
                support-title="Livret Recettes du chef"
                support-text="Taille : 200x280"
            />
            <SupportItemCard
                support-img="support6.jpg"
                support-title="Totem elliptique R/V"
                support-text="Taille : 50x154"
            />
          </div>
        </div>
      </div>
      <div class="support-slider-container">
        <SupportItemCardMobile
            support-img="support1.jpg"
            support-title="Affiche 1**"
            support-text="Taille : 60x80"
        />
        <SupportItemCardMobile
            support-img="support2.jpg"
            support-title="Affiche 2**"
            support-text="Taille : 60x80"
        />
        <SupportItemCardMobile
            support-img="support3.jpg"
            support-title="Chevalet de Comptoir*"
            support-text="Taille : 20x30"
        />
        <SupportItemCardMobile
            support-img="support4.jpg"
            support-title="Lot de mini-chevalets"
            support-text="Taille : 10x7"
        />
        <SupportItemCardMobile
            support-img="support5.jpg"
            support-title="Livret Recettes du chef"
            support-text="Taille : 200x280"
        />
        <SupportItemCardMobile
            support-img="support6.jpg"
            support-title="Totem elliptique R/V"
            support-text="Taille : 50x154"
        />
      </div>
      <div class="tw-block md:tw-hidden tw-mx-3">
        <span class="contenu-kit-detail">
              * Encre alimentaire. Les encres mis au contact des denrées alimentaires
              sont inertes vis-à-vis des denrées alimentaires. <br>
              ** pastilles double face mousse 4 coins.
        </span>
      </div>

    </div>
  </v-container>
</template>

<script>
import PrimaryTitle from "../../../components/common/PrimaryTitle"
import SupportItemCard from "./SupportItemCard"
import SupportItemCardMobile from "./SupportItemCardMobile";

export default {
  name: "SupportsDetail",
  components: {
    SupportItemCardMobile,
    SupportItemCard,
    PrimaryTitle
  }
}
</script>

<style scoped>

</style>