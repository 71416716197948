<template>
  <h1 class="title-primary">{{title}}</h1>
</template>

<script>
export default {
  name: "PrimaryTitle",
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>