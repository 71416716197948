<template>
  <p class="paragraph-primary">{{paragraph}}</p>
</template>

<script>
export default {
  name: "PrimaryText",
  props: {
    paragraph: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>