<template>
  <div>
    <v-container>
      <LineCniel class="tw-mt-8" />
    </v-container>
    <div id="form-text-container">
      <v-container class="guide-transfert">
        <div class="tw-flex tw-flex-col form-top">
          <PrimaryTitle title="Transférez le guide acheteur et le livret des recettes à vos équipes"/>
          <div id="guide-form">
            <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-space-x-3 form-top">
        <span class="form-top-text">
          Pour envoyer et recevoir le guide et le livret par e-mail, remplissez le formulaire de contact :
        </span>
              <img class="tw-cursor-pointer form-button-down-up" v-if="!displayForm" @click="displayFormHandler" src="@/assets/images/form-button-down.svg" alt="">
              <img class="tw-cursor-pointer form-button-down-up" v-if="displayForm" @click="displayFormHandler" src="@/assets/images/form-button-up.svg" alt="">
            </div>
            <GuideForm @closeForm="displayFormHandler" v-if="displayForm"/>
          </div>
        </div>
      </v-container>
    </div>
  </div>

</template>

<script>
import PrimaryTitle from "../../../components/common/PrimaryTitle";
import LineCniel from "../../../components/common/LineCniel";
import GuideForm from "./GuideForm";
export default {
  name: "GuideTransfert",

  components: {GuideForm, LineCniel, PrimaryTitle},

  data() {
    return {
      displayForm: false,
    }
  },

  methods: {
    displayFormHandler() {
      this.displayForm = !this.displayForm;
    },
  }
}
</script>

<style scoped>

</style>