<template>
  <header id="public-header">
    <div class="tw-flex tw-flex-col">
      <img src="@/assets/images/header-logo.svg" class="tw-hidden md:tw-block" alt="logo header" />
      <img src="@/assets/images/header-logo-sm.svg" class="tw-block md:tw-hidden" alt="logo header" />
      <div class="header-title-box">
        L’ EUROPE A DU BON
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>