<template>
  <v-app>
    <SnackbarComponent/>
    <router-view class="tw-bg-white"></router-view>
  </v-app>
</template>

<script>
import SnackbarComponent from "./components/common/SnackbarComponent";

export default {
  name: 'App',
  components: {
    SnackbarComponent
  }
}
</script>